import {ReactNode} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import {font_header_large} from '@/theme/fonts';

interface Props {
  children?: ReactNode;
  onClose: () => void;
  id: string;
  disableClose: boolean;
}

const ModalTitle = (props: Props) => {
  const {children, onClose, disableClose = false, ...other} = props;

  return (
    <Title {...other}>
      <Box>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} disabled={disableClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Content align="center">{children}</Content>
    </Title>
  );
};

const Title = styled(MuiDialogTitle)`
  margin: 0;
  padding: 8px 30px;

  & div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & button {
      padding: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;

const Content = styled(Typography)`
  ${font_header_large};
`;

export default ModalTitle;
