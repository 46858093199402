'use client';

import {FC, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';

import {usePathname, useSearchParams} from 'next/navigation';
import styled, {ThemeProvider} from 'styled-components';

import {TrackingProvider} from '@/src/context/Tracking/TrackingProvider';
import {UserProvider} from '@/src/context/UserData';
import useMobile from '@/src/hooks/useMobile';
import {useAppDispatch, useAppSelector} from '@/src/lib/store/hooks';
import {getProfileData, setFlow} from '@/src/lib/store/stores/profile';
import {setToggleSidebar} from '@/src/lib/store/stores/sidebar';
import theme from '@/theme/theme';

import Footer from './Footer';
import Header from './MainHeader/HeaderView';
import {handleGridStyle} from './PageLayoutUtils';
import {Sidebar} from './Sidebar';

interface PageLayoutProps {
  children: ReactNode;
  isHidesidebar?: boolean;
}

const PageLayout: FC<PageLayoutProps> = ({children, isHidesidebar}) => {
  const query = useSearchParams();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const sidebarToggle = useAppSelector((state) => state.sidebar.sidebarToggle);
  const flowSelector = useAppSelector((state) => state.profile.flow);

  const handleSidebarToggle = useCallback((value: boolean) => dispatch(setToggleSidebar(value)), []);

  const [initiallySelectedNav, setInitiallySelectedNav] = useState<string>('');

  const queryString = useMemo(() => {
    const queryParams = new URLSearchParams();
    Object.keys(query || {}).forEach((key) => {
      if (Array.isArray(query?.[key])) {
        query?.[key].forEach((value) => queryParams.append(key, value));
      } else {
        queryParams.append(key, query?.[key] as string);
      }
    });
    return queryParams.toString();
  }, [query]);

  const urlParams = useMemo(() => new URLSearchParams(queryString), [queryString]);

  useEffect(() => {
    const flow = urlParams.get('flow');
    if (flow) dispatch(setFlow(flow));
  }, [urlParams]);

  const {isLaptop} = useMobile();

  useEffect(() => {
    handleSidebarToggle(!isLaptop);
    if (pathname !== '/payment/thankyou/') {
      sessionStorage.removeItem('successfulPurchase');
    }
  }, [isLaptop, pathname]);

  const [purchaseOrdersCount] = useState<number>(0);

  useEffect(() => {
    flowSelector && dispatch(getProfileData(flowSelector));
  }, [flowSelector]);

  const openSideBar = useCallback(
    (key?: string) => {
      key && setInitiallySelectedNav(key);
      handleSidebarToggle(!sidebarToggle);
    },
    [sidebarToggle]
  );

  const isDemoProjectPage = useMemo(() => pathname?.includes('/project/demo'), [pathname]);

  const sidebarSize: number = useMemo(() => (sidebarToggle ? 280 : 88), [sidebarToggle]);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <TrackingProvider>
          <Wrapper $isDisplayed={isLaptop ? 1 : 0}>
            {!isHidesidebar && (
              <Header
                openSideBar={openSideBar}
                purchaseOrdersCount={purchaseOrdersCount}
                sidebarToggle={sidebarToggle}
              />
            )}
            <Container
              $sidebarToggle={sidebarToggle}
              $sidebarSize={sidebarSize}
              $demopage={isDemoProjectPage ? 1 : 0}
              $isHidesidebar={isHidesidebar ? 1 : 0}
            >
              {!isHidesidebar && (
                <Sidebar
                  initiallySelectedNav={initiallySelectedNav}
                  setInitiallySelectedNav={setInitiallySelectedNav}
                  openSideBar={openSideBar}
                />
              )}
              <Content>{children}</Content>
            </Container>
            {!isHidesidebar && <Footer />}
          </Wrapper>
        </TrackingProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

const Wrapper = styled.div<{$isDisplayed: number}>`
  height: 100%;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    grid-template-columns: 1fr;
    background: ${({$isDisplayed, theme}) => `${$isDisplayed ? theme.colors.grey000 : 'transparent'}`};
  }
`;

const Container = styled.div<{
  $sidebarToggle: boolean;
  $sidebarSize: number;
  $demopage: number;
  $isHidesidebar: number;
}>`
  display: grid;
  height: calc(100% - 150px);
  grid-template-columns: ${({$sidebarSize, $demopage, $isHidesidebar}) =>
    handleGridStyle($demopage, $sidebarSize, $isHidesidebar)};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    grid-template-columns: 1fr;
    background: ${({
      $sidebarToggle,
      theme: {
        colors: {grey000},
      },
    }) => `${$sidebarToggle ? grey000 : 'transparent'}`};
  }
`;

const Content = styled.div`
  height: 100%;
  overflow: auto;
  min-height: calc(100vh - 86px);
  background-color: ${({theme}) => theme.colors.grey010};
`;

export default PageLayout;
