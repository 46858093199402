import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ThemeOptionsState {
  sidebarToggle: boolean;
}

const initialState: ThemeOptionsState = {
  sidebarToggle: false,
};

const themeOptionsSlice = createSlice({
  name: 'themeOptions',
  initialState,
  reducers: {
    setSidebarToggle(state, action: PayloadAction<boolean>) {
      state.sidebarToggle = action.payload;
    },
  },
});

export const {setSidebarToggle} = themeOptionsSlice.actions;

export default themeOptionsSlice.reducer;
