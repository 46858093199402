import {FC, useCallback} from 'react';

import styled from 'styled-components';

import {MainMenuItem} from './SideBarLinks';
import BaseIcon from '../BaseComponents/BaseIcon';

interface SmallSidebarItemProps {
  item: MainMenuItem;
  userType: string;
  getMenuItemClasses: (key: string, to?: string) => string;
  onOpenClosedSidebar: (value: string) => void;
}

const SmallSidebarItem: FC<SmallSidebarItemProps> = ({
  item: {userTypes, iconComponent, icon, key, to},
  userType,
  getMenuItemClasses,
  onOpenClosedSidebar,
}) => {
  const handleOpenClosedSidebar = useCallback(() => onOpenClosedSidebar(key), [onOpenClosedSidebar, key]);

  const renderIcon = (
    <ToggleIcon
      className={getMenuItemClasses(key, to)}
      key={key}
      icon={icon}
      onClick={handleOpenClosedSidebar}
      boxH={20}
      boxW={24}
    />
  );

  if (!userTypes || userTypes.includes(userType)) {
    return iconComponent ? (
      <IconComponent key={key} className={getMenuItemClasses(key, to)} onClick={handleOpenClosedSidebar}>
        {iconComponent}
      </IconComponent>
    ) : (
      renderIcon
    );
  }
  return null;
};

const ToggleIcon = styled(BaseIcon)`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey090};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: ${({theme}) => theme.colors.blue020};
    fill: ${({theme}) => theme.colors.blue100};
    border-radius: 10px;
  }
`;

const IconComponent = styled.div`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey090};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    padding: 10px;
    box-sizing: content-box;
  }

  &.active {
    svg {
      background-color: ${({theme}) => theme.colors.blue020};
      fill: ${({theme}) => theme.colors.blue100};
      border-radius: 10px;
    }
  }
`;

export default SmallSidebarItem;
