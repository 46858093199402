import {USER_ROLES} from '@/src/types/Wizard/types';

import CONSTS from '../../HeaderConsts';

const {
  NAV_MENU_TYPES: {LINK, DROPDOWN},
} = CONSTS;

const supportParams = {
  type: LINK,
  data: {
    href: '',
    title: 'Support',
  },
  action: () => {
    fetch('/api/zendesk-auth');
  },
};

const adminNavItems = () => [
  {
    type: LINK,
    data: {href: '/cpanel/', title: 'Dashboard'},
  },
  {
    type: LINK,
    data: {href: '/my-projects/customer/', title: 'Projects'},
  },
  {
    type: LINK,
    data: {href: '/myteam', title: 'MyTeam'},
  },
  supportParams,
];

const unloggedNavItems = ({blendDomainURL}) => [
  {
    type: LINK,
    data: {
      href: `https://${blendDomainURL}/about-us`,
      title: 'About',
    },
  },
  {
    type: DROPDOWN,
    data: {
      title: 'Translators',
      links: [
        {
          href: 'https://www.onehourtranslation.com/translation/about/human-translator',
          title: 'Join as a Translator',
        },
        {
          href: `https://help.${blendDomainURL?.replace('www.', '')}`,
          title: 'Translators FAQ',
        },
      ],
    },
  },
  supportParams,
];

const translatorNavItems = () => [
  {
    type: LINK,
    data: {
      href: '/dashboard',
      title: 'Dashboard',
    },
  },
  {
    type: LINK,
    data: {
      href: '/my-projects/translator/',
      title: 'Projects',
    },
  },
  {
    type: LINK,
    data: {
      href: '/open-project-freelance-translator',
      title: 'Open Projects',
    },
  },
  supportParams,
];

const clientNavItems = ({myTeamStatus}) => {
  const items = [
    {
      type: LINK,
      data: {
        href: '/cpanel',
        title: 'Dashboard',
      },
    },
    {
      type: LINK,
      data: {
        href: '/my-projects/customer/',
        title: 'Projects',
      },
    },
    supportParams,
    {
      type: LINK,
      data: {
        href: '/voice',
        title: 'Voices',
      },
    },
  ];

  if (myTeamStatus) {
    items.splice(2, 0, {
      type: LINK,
      data: {
        href: '/myteam',
        title: 'MyTeam',
      },
    });
  }

  return items;
};

export const getNavItems = (userType: USER_ROLES, {blendDomainURL, myTeamStatus}) => {
  const navByUserType = {
    Admin: adminNavItems,
    Customer: clientNavItems,
    Translator: translatorNavItems,
  };

  const navItem = navByUserType?.[userType] || unloggedNavItems;

  return navItem({blendDomainURL, myTeamStatus});
};
