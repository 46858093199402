import {useCallback, useEffect, useMemo, useState} from 'react';

import {useRouter} from 'next/navigation';
import styled from 'styled-components';

import ShoppingBagIcon from '@/assets/icons_refactor/Header/shopping-bag.svg';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import useTracking from '@/src/context/Tracking/useTracking';
import {useUser} from '@/src/context/UserData';
import {USER_ROLES} from '@/src/types/Wizard/types';
import {GTMEvents} from '@/src/utils/globalEvents';
import {font_medium, font_small} from '@/theme/fonts';

const {translator} = USER_ROLES;

interface ShoppingCartTooltipInterface {
  dpl: string;
}

interface PurchaseOrdersCountProps {
  purchaseOrdersCount: undefined | number;
  embedded?: boolean;
}

const ShoppingCartHeader = ({purchaseOrdersCount, embedded}: PurchaseOrdersCountProps) => {
  const {gtmTrack} = useTracking();
  const {userData} = useUser();
  const router = useRouter();
  const [isTranslator, setTranslator] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const shoppingCartPage = useCallback(() => {
    gtmTrack({
      event: GTMEvents.header_click,
      user_id: userData?.id,
    });
    router.push('/shopping-cart');
  }, [userData]);

  const handleTooltip = useCallback(() => {
    localStorage.setItem('shoppingCartTooltip', '1');
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    const shoppingCartTooltip = localStorage.getItem('shoppingCartTooltip');
    if (shoppingCartTooltip) setShowTooltip(false);
  }, []);

  useEffect(() => {
    if (userData) setTranslator(userData?.type === translator);
  }, [userData]);

  const isEmptyCount = useMemo(() => (purchaseOrdersCount === 0 ? 'block' : 'none'), [purchaseOrdersCount]);
  const isCountPositive = useMemo(
    () => (!!purchaseOrdersCount && purchaseOrdersCount > 0 ? 'block' : isEmptyCount),
    [isEmptyCount, purchaseOrdersCount]
  );

  return (
    <>
      {!isTranslator && (
        <Wrapper>
          <ShoppingCartButton
            $purchaseorderscount={purchaseOrdersCount}
            onClick={shoppingCartPage}
            data-qa-auto="shopping-cart-basket-btn"
            id="shopping-cart"
            className={isEmptyCount ? 'emptyCart' : ''}
          >
            <Icon
              icon={ShoppingBagIcon}
              purchaseorderscount={purchaseOrdersCount}
              boxW={24}
              boxH={24}
              embedded={embedded ? 1 : 0}
            />
            {!!purchaseOrdersCount && purchaseOrdersCount > 0 && (
              <ShoppingCartCount>{purchaseOrdersCount}</ShoppingCartCount>
            )}
          </ShoppingCartButton>
          <TooltipElement onClick={handleTooltip} dpl={showTooltip ? isCountPositive : 'none'}>
            <TooltipContent>
              {!!purchaseOrdersCount && purchaseOrdersCount > 0 ? (
                <>
                  <New className="new">NEW</New>
                  <Text>Find all your project drafts here.</Text>
                </>
              ) : (
                purchaseOrdersCount === 0 && (
                  <span>It looks like you don’t have any project drafts at this moment.</span>
                )
              )}
            </TooltipContent>
          </TooltipElement>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
`;

export const ShoppingCartButton = styled(BaseButton)<{$purchaseorderscount?: number}>`
  padding: 0;
  min-width: unset;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  margin-left: auto;

  &.MuiButton-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({
    $purchaseorderscount,
    theme: {
      colors: {blue100, grey030},
    },
  }) =>
    !!$purchaseorderscount && $purchaseorderscount > 0
      ? `
        background-color: ${blue100} !important;
        
        &:hover {
          background-color: rgb(117, 122, 255) !important;
        }
      `
      : $purchaseorderscount === 0 &&
        `
        background-color: ${grey030} !important;
        
        &:hover {
          background-color: rgb(222, 222, 252) !important;
        }
    `};

  @media (max-width: 425px) {
    width: 36px;
    height: 36px;

    & img {
      width: 12px;
    }
  }
`;

export const ShoppingCartCount = styled.div`
  ${font_small};
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  border: 2px solid ${({theme}) => theme.colors.grey000};
  top: -10px;
  left: 24px;
  border-radius: 50%;
  color: ${({theme}) => theme.colors.grey000};
  background-color: rgb(224, 79, 79);
  width: 25px;
  height: 25px;
  line-height: 20px;
  padding: 1px;
  box-sizing: border-box;

  @media (max-width: 425px) {
    top: -8px;
    left: 18px;
    line-height: 16px;
    width: 20px;
    height: 20px;
  }
`;

const New = styled.span`
  ${font_medium};
  padding: 3px 5px;
  background-color: ${({theme}) => theme.colors.blue100};
  border-radius: 4px;
  margin-right: 10px;
  height: 22px;
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.grey000};
`;

const Text = styled.span`
  ${font_medium};
  width: 140px;
  color: ${({theme}) => theme.colors.grey000};
`;

const TooltipElement = styled.div<ShoppingCartTooltipInterface>`
  display: ${({dpl}) => dpl};
  width: 212px;
  position: absolute;
  right: -40px;
  bottom: -59px;
  z-index: 11;
  font-size: 13px;
  border-radius: 5px;
  white-space: normal;
  padding: 5px 10px;
  text-align: start;
  background: ${({theme}) => theme.colors.grey100};
  color: ${({theme}) => theme.colors.grey000};
  box-sizing: border-box;

  &::before {
    content: ' ';
    border-width: 11px;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    top: -10px;
    left: 152px;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: ${({theme}) => theme.colors.grey100};
  }
`;

const TooltipContent = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: default;
`;

const Icon = styled(BaseIcon)<{purchaseorderscount: undefined | number; embedded: number}>`
  fill: ${({
    purchaseorderscount,
    theme: {
      colors: {blue100, grey000},
    },
  }) => (purchaseorderscount ? grey000 : blue100)} !important;
  width: 22px;
  height: 22px;
  display: block !important;
  position: absolute;
  top: ${({embedded}) => (embedded ? 8 : 7)}px;
  left: 7px;
`;

export default ShoppingCartHeader;
