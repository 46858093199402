import {FC} from 'react';

import Image from 'next/image';

interface Props {
  src: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
  onLoad?: () => void;
  width?: number;
  height?: number;
}

const BaseImage: FC<Props> = ({src, alt, className, onClick, onLoad, width, height, ...other}) => (
  <Image
    {...other}
    width={width}
    height={height}
    src={src}
    alt={alt || ''}
    className={className}
    onClick={onClick}
    onLoad={onLoad}
  />
);

export default BaseImage;
