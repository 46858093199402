'use client';

import {FC} from 'react';

import {AppRouterCacheProvider} from '@mui/material-nextjs/v14-appRouter';
import {QueryClient, QueryClientProvider} from 'react-query';

import StoreProvider from '@/src/app/StoreProvider';
import {TrackingProvider} from '@/src/context/Tracking/TrackingProvider';
import {UserProvider} from '@/src/context/UserData';

import {PropsWithPreloadedState} from '../interfaces/types/layout';

const queryClient = new QueryClient();

const Providers: FC<PropsWithPreloadedState> = ({children, preloadedState}) => (
  <AppRouterCacheProvider options={{enableCssLayer: true}}>
    <QueryClientProvider client={queryClient}>
      <StoreProvider preloadedState={preloadedState}>
        <UserProvider>
          <TrackingProvider>{children}</TrackingProvider>
        </UserProvider>
      </StoreProvider>
    </QueryClientProvider>
  </AppRouterCacheProvider>
);

export default Providers;
