import {useContext} from 'react';

import {ITrackingStateType} from './tracking.types';
import {TrackingContext} from './TrackingProvider';

const useTracking = (): ITrackingStateType => {
  const context = useContext(TrackingContext);

  if (context === undefined) {
    throw new Error('useTracking must be used within a provider');
  }

  return context;
};

export default useTracking;
