import {configureStore} from '@reduxjs/toolkit';

import {api} from './stores/api';
import config from './stores/config';
import profile from './stores/profile';
import session from './stores/session';
import sidebar from './stores/sidebar';
import themeOptions from './stores/themeOptions';
import user from './stores/user';

export const makeStore = (preloadedState = {}) => {
  return configureStore({
    reducer: {session, config, sidebar, [api.reducerPath]: api.reducer, profile, themeOptions, user},
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  });
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
