import {FC, useEffect, useMemo, useState} from 'react';

import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import IconButton from '@mui/material/IconButton';
import {usePathname} from 'next/navigation';
import styled from 'styled-components';

import AuthModal from '@/src/components/AuthModal';
import ShoppingCartHeader from '@/src/components/ShoppingCart/ShoppingCartHeader';
import {useUser} from '@/src/context/UserData';
import useMobile from '@/src/hooks/useMobile';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import {USER_ROLES} from '@/src/types/Wizard/types';
import {largeFontSize} from '@/theme/fonts';

import AuthButtons from './AuthButtons';
import AdminHeaderDropdown from './components/AdminHeaderDropdown';
import Nav from './components/Nav/Navs';
import Logo from './HeaderLogo';

interface Props {
  purchaseOrdersCount: number;
  openSideBar?: any;
  sidebarToggle?: boolean;
}

const {translator, admin} = USER_ROLES;

const HeaderView: FC<Props> = ({purchaseOrdersCount, openSideBar, sidebarToggle}) => {
  const {isTablet} = useMobile();
  const pathname = usePathname();
  const isInAuthPage = pathname?.includes('/auth');

  const [activeModalMode, setActiveModalMode] = useState<ModalMode>('');
  const {isLoggedIn, createdUserId, userData} = useUser();

  const handleCloseModal = () => setActiveModalMode('');

  const handleOpenModal = (type: ModalMode) => setActiveModalMode(type);

  useEffect(() => {
    handleCloseModal();
  }, [isLoggedIn, createdUserId, userData]);

  const isPaymentPage = !!pathname?.includes('/payment');

  const isAdmin: boolean = useMemo(() => userData?.type === admin, [userData?.type]);

  return (
    <GeneralWrapper>
      <Wrapper>
        <Logo isDarkMode />
        <Nav />
        {isLoggedIn && (
          <MenuBlock>
            <UserBlock $istranslator={userData?.type === translator ? 1 : 0}>
              {!isPaymentPage && <ShoppingCartHeader purchaseOrdersCount={purchaseOrdersCount} />}
            </UserBlock>

            {isTablet && !isPaymentPage && (
              <MenuBtn onClick={openSideBar} data-autotest-attr="hamburger">
                {!sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
              </MenuBtn>
            )}

            {isAdmin && <AdminHeaderDropdown />}
          </MenuBlock>
        )}

        {!isLoggedIn && !isInAuthPage ? <AuthButtons handleOpenModal={handleOpenModal} /> : <div></div>}
      </Wrapper>

      {activeModalMode && (
        <AuthModal
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          mode={activeModalMode}
          registrationPage=""
        />
      )}
    </GeneralWrapper>
  );
};

const GeneralWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.grey100};
  height: 57px;
  align-items: center;
  padding: 0 44px;
`;

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.grey100};
  height: 57px;
  align-items: center;
  padding: 0 44px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 3;

  & .nav-item {
    & a {
      ${largeFontSize};
      color: ${({theme}) => theme.colors.grey000};
      font-weight: 400;

      &:hover {
        color: rgb(138, 136, 255);
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0 25px;

    .nav {
      display: none;
    }
  }
`;

const UserBlock = styled.div<{$istranslator: number}>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: ${({$istranslator}) => ($istranslator ? 'auto' : '80px')};
`;

const MenuBlock = styled.div`
  display: flex;
  align-items: center;
`;

const MenuBtn = styled(IconButton)`
  color: ${({theme}) => theme.colors.grey000};
  width: 50px;
`;

export default HeaderView;
