import {FC} from 'react';

import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import useMobile from '@/src/hooks/useMobile';
import {ModalMode} from '@/src/interfaces/types/auth.types';

interface Props {
  handleOpenModal: (type: ModalMode) => void;
}

const AuthButtons: FC<Props> = ({handleOpenModal}) => {
  const {isMobile} = useMobile();

  return (
    <Wrapper>
      <LoginButton onClick={() => handleOpenModal('login')}>Sign in</LoginButton>

      {!isMobile ? (
        <RegisterButton onClick={() => handleOpenModal('signup')}>
          <span style={{color: 'white'}}>Register</span>
        </RegisterButton>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding-right: 10px;
`;

const LoginButton = styled(BaseButton)`
  background: #5857ff;
  color: ${({theme}) => theme.colors.grey000};
  border: none;
  font-weight: bold;
  margin-right: 5px;
  white-space: nowrap;
  margin-left: -25px;
`;

const RegisterButton = styled(BaseButton)`
  background: #454d55;
  color: ${({theme}) => theme.colors.grey000};
  border: none;
`;

export default AuthButtons;
